










import { Component, Prop, Vue } from 'vue-property-decorator';
import VueCkeditor from 'vue-ckeditor2';
import { Placeholder } from '@/statics/placeholders';

@Component({
  inheritAttrs: false,
  components: { VueCkeditor }
})
export default class WysiwygEditor extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Object, default: () => ({}) }) ekcConfig!: object;
  @Prop({ type: Object, default: () => ({}) }) placeholders!: Placeholder[];

  defaultConfig = {
    toolbar: [
      { name: 'tools', items: ['Maximize'] },
      { name: 'clipboard', items: ['SelectAll', 'Cut', 'Copy', 'Paste', 'Undo', 'Redo'] },
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
      { name: 'insert', items: ['HorizontalRule'] }
    ],
    stylesSet: [
      /* Inline Styles */
      {
        name: 'Einfach',
        element: 'div',
        styles: {
          'line-height': '1rem'
        }
      },
      {
        name: '1.15 Fach',
        element: 'div',
        styles: {
          'line-height': '1.15rem'
        }
      },
      {
        name: 'Standard',
        element: 'div',
        styles: {
          'line-height': '1.4rem'
        }
      },
      {
        name: '1.5 Fach',
        element: 'div',
        styles: {
          'line-height': '1.5rem'
        }
      },
      {
        name: 'Doppelt',
        element: 'div',
        styles: {
          'line-height': '2rem'
        }
      }
    ],
    bodyClass: 'document-editor',
    removeDialogTabs: 'image:advanced;link:advanced',
    disallowedContent: 'img{width,height,float}',
    extraAllowedContent: 'img[width,height,align]',
    format_tags: 'div;p;h1;h2;h3;pre',
    removePlugins: 'magicline',
    height: 1000
  };

  editor: any = null;

  get mergedConfig() {
    return {
      ...this.defaultConfig,
      ...this.ekcConfig
    };
  }

  get content(): string | undefined {
    return this.value;
  }

  set content(value: string | undefined) {
    this.$emit('input', value);
  }

  ready(event: any) {
    this.editor = event.editor;

    this.$emit('ready', event);
  }
}
