












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { emailPlaceholder, letterPlaceholder, Placeholder } from '@/statics/placeholders';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable }
})
export default class PlaceholderTable extends Vue {
  @Prop({ type: Boolean, default: false }) conservatorRequired!: boolean;
  @Prop({ type: Boolean, default: false }) bankAccountRequired!: boolean;
  @Prop({ type: String, default: 'letter' }) type!: string;

  placeholder: string | null = null;
  search: string = '';
  snackbar: boolean = false;

  get headers() {
    return [
      { align: 'left', text: '', value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('common.placeholder'), value: 'placeholder' },
      { align: 'left', text: this.$i18n.t('common.description'), value: 'description' }
    ];
  }

  get placeholders(): Placeholder[] {
    if (this.type === 'letter') {
      return letterPlaceholder(this.conservatorRequired, this.bankAccountRequired);
    }

    if (this.type === 'email') {
      return emailPlaceholder(this.conservatorRequired, this.bankAccountRequired);
    }

    return [];
  }

  onCopy(event: any) {
    this.placeholder = event.text;
    this.snackbar = true;
  }
}
