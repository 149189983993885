













import { Component, Prop, Vue } from 'vue-property-decorator';
import { Visibility } from '../types';
import { Option } from '@/components/types';

@Component({})
export default class ReceiverTypeSelect extends Vue {
  @Prop({ type: String, default: 'private' }) value!: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  list: Array<Option<Visibility>> = [
    { value: 'public', text: 'Für alle verfügbar' },
    { value: 'private', text: 'Für mich verfügbar' }
  ];
}
